<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Styles List
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plans</a></li>
          <li class="breadcrumb-item active" aria-current="page">Styles</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterStyles" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                        <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ list_title }}</h4>
            <b-table :items="styles" id="table-list" responsive :busy="isBusy" :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge pill :variant="statusClass(data.item.status)">
                  {{ statusText(data.item.status) }}
                </b-badge>
              </template>
              <template v-slot:cell(action)="data">
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'style-update', params: {id: data.item.id} }">Edit</router-link>
                </b-badge>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */

import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import { ValidationObserver } from "vee-validate"
import _ from 'lodash'

import helper from '../../util/helper.js'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      list_title: "Styles",
      city_id: 1,
      currentPage: 1,
      perPage: 0,
      isBusy: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'city', sortable: true },
        { key: 'status', sortable: true },
        { key: 'mapbox_style_id', sortable: true },
        { key: 'commercial_fill_color', sortable: true },
        { key: 'residential_fill_color', sortable: true },
        { key: 'default_plot_fill_color', sortable: true },
        { key: 'commercial_font_color', sortable: true },
        { key: 'residential_font_color', sortable: true },
        { key: 'updated_at', sortable: true },
        { key: 'action', sortable: false }
      ],
    };
  },
  components: {
    ValidationObserver
  },
  computed: {
    styles() {
      return this.$store.state.plan.styles
    },
    cities() {
      let cities = this.$store.getters['city/listCities']
      cities.unshift({value: "", text: "Select City"})
      return cities
    }
  },
  watch: {
  },
  methods: {
    filterStyles: _.debounce(function() {
      this.fetchStyles()
    }, 2000),
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    statusText(val) {
      if(val == "1") return "Completed"
      return "Processing"
    },
    statusClass(val) {
      if(val == "0") return "warning"
      if(val == "1") return "success"
      return "danger"
    },
    fetchCities() {
      this.isBusy = true

      const payload = {
        meta: {
          page: 1,
          paginate: 200
        }
      }
      this.$store.dispatch("city/fetchCities", payload)
    },
    fetchStyles() {
      this.isBusy = true

      const payload = {
        meta: {
          city: this.city_id
        },
      }
      this.$store.dispatch("plan/fetchStyles", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
  },
  created() {
    this.fetchStyles()
    this.fetchCities()
 },
}
</script>
